import { EStyleType } from '@/components/layout/header/components/styledMy/useStyledMy';

export function getBgColor(styleType: EStyleType): string {
  switch (styleType) {
    case EStyleType.none:
      return 'FFFFFF00';
    case EStyleType.light:
      return '#F9CAE066';
    case EStyleType.lightAlt:
      return '#F9CAE0';
    case EStyleType.default:
    default:
      return '#FCE4EF';
  }
}
